import { Field } from "formik"
import { FormikErrorMsg, InfoIconWithTooltip, Label } from ".."

export function Input(props: any) {
    const { className, name, tooltip, ...rest } = props
    return (
        <div className={className}>
            <Label {...rest} />
            {tooltip && <InfoIconWithTooltip tooltip={tooltip} />}
            <Field id={name} name={name} {...rest} />
            <FormikErrorMsg name={name} />
        </div>
    )
}
