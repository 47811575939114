import { useState } from "react"
import Link from "../../Link"
import classes from "./Navigation.module.scss"
import { routes } from "../../../pages/routes"
import { NavToggler } from "./NavToggler"

/**
                <li>
                  <Link
                    name="skills"
                    text="Skills und Lebenslauf"
                    location="/skills"
                  />
                </li>
                <li>
                  <Link
                    name="tagebuch"
                    text="Persönliches Tagebuch (Fitness / Gewicht / etc. pp)"
                    location="/tagebuch"
                  />
                </li>
                <li>
                  <Link
                    name="kontenmodel"
                    text="Kontenmodel"
                    location="/kontenmodel"
                  />
                </li>
                <li>
                  <Link
                    name="dezimal-binaer"
                    text="Umrechner dezimal vs. binär"
                    location="/dezimal-binaer"
                  />
                </li>
                <li>
                  <Link name="zitate" text="Zitat-Box" location="/zitate" />
                </li>
                <li>
                  <Link
                    name="weeklyRandomDiet"
                    text="Wöchentlicher Zufalls Ernährungsplan"
                    location="/weeklyRandomDiet"
                  />
                </li>
                <li>
                  <Link
                    name="brainTrainSkillGames"
                    text="Brain Train Skill Games"
                    location="/brainTrainSkillGames"
                  />
                </li>
                */

export default function Navigation() {
    const [checked, setChecked] = useState(false)

    const handleClick = () => {
        setChecked(false)
    }

    const handleCheck = () => {
        if (checked) {
            setChecked(false)
        } else {
            setChecked(true)
        }
    }

    return (
        <nav className={classes.nav}>
            <NavToggler name="navToggler" className={classes.toggler} checked={checked} onClick={handleCheck} />
            <div className={classes.hamburger}>
                <div></div>
            </div>
            <div className={classes.menu}>
                <div>
                    <div>
                        <ul>
                            <li>
                                <Link name="home" text="Startseite" location={routes.home} onClick={handleClick} />
                            </li>
                            <li>
                                <Link name="ffmi" text="FFMI - Rechner" location={routes.rechner.ffmi} onClick={handleClick} />
                            </li>
                            <li>
                                <Link name="navy-kfa" text="Navy-KFA-Rechner" location={routes.rechner.navyKFA} onClick={handleClick} />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    )
}
