import { ListGroup, ListGroupItem } from "../../components/ListGroup"
import { FormikControl, FormikForm } from "../../components/Form"
import classes from "./NavyKFA.module.scss"
import { gender as genderType, initialValues, NavyKFAForm, validateNavyKFAForm } from "../../data/navykfa"

export default function NavyKFA() {
    const dropdownOptions = [
        { key: "Wähle ein Geschlecht aus", value: "" },
        { key: genderType.MALE, value: "Mann" },
        { key: genderType.FEMALE, value: "Frau" },
    ]

    const onSubmit = (values: NavyKFAForm) => {
        switch (values.gender) {
            case "Mann":
                values.bfp = 86.01 * Math.log10(values.belly - values.neck) - 70.041 * Math.log10(values.size) + 30.3
                break
            case "Frau":
                values.bfp = 163.205 * Math.log10(values.waist + values.ass - values.neck) - 97.684 * Math.log10(values.size) + 104.912
                break
            default:
                values.bfp = 0
        }
    }

    return (
        <div className={classes.NavyKFA}>
            <h1>Navy-KFA-Rechner</h1>

            <FormikForm<NavyKFAForm>
                initialValues={initialValues}
                validationSchema={validateNavyKFAForm}
                onSubmit={onSubmit}
                formStyle={classes.navyKFAform}
                submitBtnLbl="Berechne KFA"
            >
                {(props) => (
                    <>
                        <FormikControl
                            control="select"
                            label="Geschlecht:"
                            required
                            name="gender"
                            options={dropdownOptions}
                            tooltip="Geschlecht zur Berechnung"
                            className={classes.formikField}
                        />
                        <FormikControl
                            control="input"
                            label="Körpergröße:"
                            required
                            type="number"
                            name="size"
                            tooltip="Körpergröße in cm"
                            min={0}
                            max={250}
                            step={0.1}
                            className={classes.formikField}
                        />
                        <FormikControl
                            control="input"
                            label="Nackenumfang:"
                            required
                            type="number"
                            name="neck"
                            tooltip="Nackenumfang in cm"
                            min={0}
                            max={50}
                            step={1}
                            className={classes.formikField}
                        />
                        <FormikControl
                            control="input"
                            label="Bauchumfang:"
                            required={Boolean(props.values.gender === genderType.MALE)}
                            type="number"
                            name="belly"
                            tooltip="Bauchumfang in cm"
                            min={0}
                            max={150}
                            step={1}
                            className={classes.formikField}
                        />
                        <FormikControl
                            control="input"
                            label="Taillenumfang:"
                            required={Boolean(props.values.gender === genderType.FEMALE)}
                            type="number"
                            name="waist"
                            tooltip="Taillenumfang in cm"
                            min={0}
                            max={100}
                            step={1}
                            className={classes.formikField}
                        />
                        <FormikControl
                            control="input"
                            label="Poumfang:"
                            required={Boolean(props.values.gender === genderType.FEMALE)}
                            type="number"
                            name="ass"
                            tooltip="Poumfang in cm"
                            min={0}
                            max={50}
                            step={1}
                            className={classes.formikField}
                        />
                        <FormikControl
                            control="input"
                            label="Körperfettanteil:"
                            disabled
                            type="number"
                            name="bfp"
                            tooltip="Berechneter Körperfettanteil in %"
                            className={classes.formikField}
                        />
                    </>
                )}
            </FormikForm>

            <h3>Wie misst man die Umfänge?</h3>
            <ListGroup>
                <ListGroupItem title="Bauchumfang:">
                    Der Bauchumfang wird auf der Höhe des Bauchnabels genommen. Das Maßband muss parallel zum Boden angelegt werden. Den Wert nach entspannter
                    Ausatmung nehmen.
                </ListGroupItem>
                <ListGroupItem title="Nackenumfang:">
                    Der Nackenumfang wird direkt unter (nicht auf) dem Adamsapfel abgenommen. Die Schultern dürfen nicht hochgezogen werden. Achte darauf, dass
                    das Maßband so horizontal wie möglich anliegt.
                </ListGroupItem>
                <ListGroupItem title="Taillenumfang:">
                    Der Taillenumfang wird an der schmalsten Stelle zwischen unterem Brustkorb und Bauchnabel abgenommen. Meist befindet sich die Taille genau
                    in der Mitte dieser beiden Punkte. Maßband horizontal halten.
                </ListGroupItem>
                <ListGroupItem title="Poumfang:">
                    Der Poumfang sollte an der Stelle genommen werden, die – von der Seite betrachtet – am weitesten von der Körpermitte absteht. Stelle sicher,
                    dass das Maßband parallel zum Boden anliegt.
                </ListGroupItem>
            </ListGroup>

            <h3>Berechnungsformeln des US-Navy KFA Rechners:</h3>
            <ListGroup>
                <ListGroupItem title="Männer:">KFA = 86,010 * LOG( B - N ) - 70,041 * LOG(K) + 30,30</ListGroupItem>
                <ListGroupItem title="Frauen:">KFA = 163,205 * LOG( T + P - N ) - 97,684 * LOG(K) + 104,912</ListGroupItem>
            </ListGroup>

            <h3>Dabei bedeuten die Abkürzungen:</h3>
            <ListGroup>
                <ListGroupItem title="K:">Körpergröße</ListGroupItem>
                <ListGroupItem title="B:">Bauchumfang</ListGroupItem>
                <ListGroupItem title="N:">Nackenumfang</ListGroupItem>
                <ListGroupItem title="T:">Taillenumfang (nur bei Frauen)</ListGroupItem>
                <ListGroupItem title="P:">Poumfang (nur bei Frauen)</ListGroupItem>
            </ListGroup>
        </div>
    )
}
