import Link from "../../Link"
import classes from "./Footer.module.scss"

export function Footer() {
    return (
        <div className={classes.footer}>
            <div className={classes.infos}>
                <div className={classes.contact}>
                    <ul>
                        <li></li>
                    </ul>
                </div>
                <div className={classes.linklist}>
                    <ul>
                        <li></li>
                    </ul>
                </div>
            </div>
            <div className={classes.copyright}>
                © 2020 Jakob Risy. Alle Rechte vorbehalten |
                <Link name="impressum" text="Impressum" location="/impressum" />
            </div>
            <div className={classes.clearfloat}></div>
        </div>
    )
}
