import * as Yup from "yup"

export type FFMIForm = {
    size: number
    kfa: number
    weight: number
    result?: number
}

export const initialValues = {
    size: 180,
    kfa: 20,
    weight: 80,
    result: 0,
}

export const validateFFMIForm = Yup.object({
    size: Yup.number().min(40, "Nicht kleiner als 40 cm").max(250, "Nicht größer als 250 cm").required("Pflichtfeld"),
    kfa: Yup.number().min(7, "Nicht kleiner als 7").max(40, "Nicht größer als 40").required("Pflichtfeld"),
    weight: Yup.number().required("Pflichtfeld"),
    result: Yup.number().notRequired(),
})
