interface NavTogglerProps {
    checked: boolean
    name: string
    className: string
    onClick: () => void
}

export function NavToggler({ name, className, checked, onClick }: NavTogglerProps) {
    return <input type="checkbox" name={name} className={className} checked={checked} onClick={onClick} />
}
