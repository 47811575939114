import { Field } from "formik"
import { Label, InfoIconWithTooltip } from ".."
import { FormikErrorMsg } from "../FormikErrorMsg"

export function Textarea(props: any) {
    const { className, name, tooltip, ...rest } = props
    return (
        <div className={className}>
            <Label {...rest} />
            {tooltip && <InfoIconWithTooltip tooltip={tooltip} />}
            <Field as="textarea" id={name} name={name} {...rest} />
            <FormikErrorMsg name={name} />
        </div>
    )
}
