import * as Yup from "yup"

export const gender = {
    MALE: "Mann",
    FEMALE: "Frau",
}

export type Gender = typeof gender

export type NavyKFAForm = {
    gender: string
    size: number
    neck: number
    belly: number
    waist: number
    ass: number
    bfp?: number
}

export const initialValues = {
    gender: "",
    size: 180,
    neck: 38,
    belly: 80,
    waist: 75,
    ass: 90,
    bfp: 0,
}

export const validateNavyKFAForm = Yup.object({
    size: Yup.number().required("Pflichtfeld"),
    gender: Yup.mixed().oneOf(["Mann", "Frau"]).required("Pflichtfeld"),
    neck: Yup.number().required("Pflichtfeld"),
    belly: Yup.number().when("gender", {
        is: gender.MALE,
        then: Yup.number().required("Pflichtfeld"),
        otherwise: Yup.number().notRequired(),
    }),
    waist: Yup.number().when("gender", {
        is: gender.FEMALE,
        then: Yup.number().required("Pflichtfeld"),
        otherwise: Yup.number().notRequired(),
    }),
    ass: Yup.number().when("gender", {
        is: gender.FEMALE,
        then: Yup.number().required("Pflichtfeld"),
        otherwise: Yup.number().notRequired(),
    }),
    bfp: Yup.number().notRequired(),
})
