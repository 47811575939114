/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-unused-vars */
import { Formik, FormikConfig, FormikHelpers, FormikValues } from "formik"
import { useCallback } from "react"
import { Form } from "react-bootstrap"

export type FormikFormProps<Values extends FormikValues = FormikValues> = {
    onSuccess?: () => void
    formStyle?: string
    submitBtnLbl?: string
} & FormikConfig<Values>

export function FormikForm<Values extends FormikValues = FormikValues>(formProps: FormikFormProps<Values>) {
    const { onSubmit, onSuccess, children, formStyle, submitBtnLbl, ...rest } = formProps

    const onSubmitHandler = useCallback<(values: Values, formikHelpers: FormikHelpers<Values>) => void>(
        async (values, formikHelpers) => {
            await onSubmit(values, formikHelpers)
            onSuccess?.()
        },
        [onSubmit, onSuccess]
    )

    return (
        <Formik onSubmit={onSubmitHandler} enableReinitialize {...rest}>
            {(props) => (
                <Form className={formStyle} noValidate onSubmit={props.handleSubmit}>
                    {typeof children === "function" ? children(props) : children}
                    <button type="submit" disabled={!props.isValid}>
                        {submitBtnLbl}
                    </button>
                </Form>
            )}
        </Formik>
    )
}
