import "react-toastify/dist/ReactToastify.min.css"
import { Header } from "./Header"
import { Footer } from "./Footer"

import classes from "./Layout.module.scss"

export function Layout(props: any) {
    const { title, children } = props
    return (
        <div title={title} className={classes.layout}>
            <Header />
            <main className={classes.main}>{children}</main>
            <Footer />
        </div>
    )
}
