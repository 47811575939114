export const routes = {
    home: "/",
    contact: "/kontakt",
    impressum: "/impressum",
    login: { default: "/anmelden" },
    logout: "/abmelden",
    rechner: {
        default: "/rechner",
        ffmi: "/rechner/fett-freie-masse-index",
        navyKFA: "/rechner/navy-koerper-fett-anteil",
        dezimalBinaer: "/rechner/dezimal-binaer",
    },
    own: {
        default: "/jakob",
        skills: "/jakob/skills",
        tagebuch: "/jakob/tagebuch",
    },
    knowHow: {
        default: "/wissensmanagement",
        kontenmodel: "/wissensmanagement/kontenmodel",
        weeklyRandomDiet: "/wissensmanagement/woechentlicherZufallsErnaehrungsplan",
    },
    games: {
        default: "/spiele",
        brainTrainSkillGames: "/spiele/gehirnTrainingsSpiele",
    },
    zitate: "/zitate",
}
