import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import { ListGroup, ListGroupItem } from "../../components/ListGroup"
import { Panel } from "../../components/Panel"
import classes from "./Home.module.scss"

export default function Home() {
    return (
        <div className={classes.home}>
            <Panel title="Infos" open={true}>
                <p>Auf meiner Webseite, findest du verschiedene Tools zur Berechnung von verschiedensten Werten</p>
                <p>
                    Gerne kannst du mir auch neue Ideen für nützliche Tools mailen an
                    <br />
                    <Link to="#" onClick={() => window.open("mailto:J.Risy@web.de")}>
                        J.Risy@web.de
                    </Link>
                    <br />
                    und ich werde diese SSWIM umsetzen ;-)
                </p>
            </Panel>
            <Panel title="Wer bin Ich?" open={false}>
                <ListGroup>
                    <ListGroupItem title="Name:">Jakob Risy</ListGroupItem>
                    <ListGroupItem title="Alter:">36 Jahre</ListGroupItem>
                    <ListGroupItem title="Familienstand:">verheiratet, 2 Kinder</ListGroupItem>
                    <ListGroupItem title="Arbeitgeber:">
                        <Link to="#" onClick={() => window.open("http://nortal.com/de")}>
                            Nortal AG
                        </Link>
                    </ListGroupItem>
                    <ListGroupItem title="Werte:">zielorientiert, kommunikativ, humorvoll, treu, liebevoll</ListGroupItem>
                    <ListGroupItem title="Hobbies:">Programmieren, Fotografieren, Musik hören, Walking</ListGroupItem>
                    <ListGroupItem title="Lebensmotto:">Klag nicht, kämpf!</ListGroupItem>
                    <ListGroupItem title="">
                        KLUW: <u>K</u>onstant <u>L</u>ernen <u>U</u>nd <u>W</u>achsen <FontAwesomeIcon icon={faAngleDoubleRight} /> Podcasts, Bücher, Webinare,
                        Onlineevents
                    </ListGroupItem>
                </ListGroup>
            </Panel>
            <Panel title="Was sind meine Interessen?" open={false}>
                <div>Persönlichkeitsentwicklung</div>
                <div>Fitness / Ernährung</div>
                <div>Finanzelle Bildung</div>
            </Panel>
            <Panel title="Was kann ich Dir bieten?" open={false}>
                <div>Wissen rund um den PC</div>
                <div>Erfahrungen im Bereich Fitness / Ernährung</div>
                <div>Erfahrung im Bereich Finanzen</div>
            </Panel>
            <Panel title="Womit kann ich Dir helfen?" open={false}>
                <div>Programmieren (JAVA, PHP, REACT, HTML, CSS)</div>
                <div>Office Programme (Excel, Word, PowerPoint)</div>
                <div>Bilanz / Haushaltsbuch / Budgetierung / Mathematik</div>
            </Panel>
            <Panel title="Was sind meine Qualifikationen?" open={false}>
                <div>Fachhochschulereife</div>
                <div>staatl. geprüfter Wirtschaftsinformatiker</div>
                <div>Anwendungsentwickler</div>
                <div>Netzwerk-Service-Techniker</div>
                <div>Netzwerkkommunikator</div>
                <div>Call-Center-Agent</div>
            </Panel>
            <Panel title="Meine Online-Profile?" open={false}>
                <ListGroup>
                    <ListGroupItem title="Xing">
                        <Link to="#" onClick={() => window.open("https://www.xing.com/profile/Jakob_Risy")}>
                            Jakob_Risy
                        </Link>
                    </ListGroupItem>
                    <ListGroupItem title="GitHub">
                        <Link to="#" onClick={() => window.open("https://github.com/JakobRisy")}>
                            JakobRisy
                        </Link>
                    </ListGroupItem>
                    <ListGroupItem title="Facebook">
                        <Link to="#" onClick={() => window.open("https://www.facebook.com/jakob.risy.5")}>
                            jakob.risy.5
                        </Link>
                    </ListGroupItem>
                    <ListGroupItem title="Instagramm">
                        <Link to="#" onClick={() => window.open("https://www.instagram.com/jakob.risy/")}>
                            jakob.risy
                        </Link>
                    </ListGroupItem>
                    <ListGroupItem title="TikTok">
                        <Link to="#" onClick={() => window.open("https://www.tiktok.com/@jakob.risy")}>
                            @jakob.risy
                        </Link>
                    </ListGroupItem>
                    <ListGroupItem title="Fitbit">
                        <Link to="#" onClick={() => window.open("https://www.fitbit.com/user/89MPMF")}>
                            89MPMF
                        </Link>
                    </ListGroupItem>
                </ListGroup>
            </Panel>
        </div>
    )
}
