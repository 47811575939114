import { Field } from "formik"
import { FormikErrorMsg, InfoIconWithTooltip, Label } from ".."

export function Select(props: any) {
    const { className, name, options, tooltip, ...rest } = props
    return (
        <div className={className}>
            <Label {...rest} />
            {tooltip && <InfoIconWithTooltip tooltip={tooltip} />}
            <Field as="select" id={name} name={name} {...rest}>
                {options.map((option: any) => {
                    return (
                        <option key={option.value} value={option.value}>
                            {option.key}
                        </option>
                    )
                })}
            </Field>
            <FormikErrorMsg name={name} />
        </div>
    )
}
