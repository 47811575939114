import { Table } from "react-bootstrap"
import { FormikForm, FormikControl } from "../../components/Form"
import { FFMIForm, initialValues, validateFFMIForm } from "../../data/ffmi"
import classes from "./FFMI.module.scss"

export default function FFMI() {
    const onSubmit = (values: FFMIForm) => {
        const ffm = (values.weight * (100 - values.kfa)) / 100
        const sizeInMeter = values.size / 100
        const ffmi = ffm / (sizeInMeter * sizeInMeter) + 6.3 * (1.8 - sizeInMeter)

        values.result = ffmi
        console.log("" + values.size + " / " + values.kfa + " / " + values.weight + " // " + ffmi)
    }

    return (
        <div className={classes.FFMI}>
            <h1>FFMI - Rechner</h1>
            <FormikForm
                initialValues={initialValues}
                validationSchema={validateFFMIForm}
                onSubmit={onSubmit}
                formStyle={classes.ffmiForm}
                submitBtnLbl="Berechne FFMI"
            >
                {() => (
                    <>
                        <FormikControl
                            control="input"
                            label="Größe (cm):"
                            required
                            type="number"
                            name="size"
                            tooltip="Wie groß bist Du?"
                            min={0}
                            max={250}
                            step={0.1}
                            className={classes.formikField}
                        />
                        <FormikControl
                            control="input"
                            label="Körperfettanteil (%):"
                            required
                            type="number"
                            name="kfa"
                            tooltip="Wieviel Körperfett hast Du?"
                            step={0.1}
                            className={classes.formikField}
                        />
                        <FormikControl
                            control="input"
                            label="Körpergewicht (kg):"
                            required
                            type="number"
                            name="weight"
                            tooltip="Wieviel wiegst Du?"
                            step={0.1}
                            className={classes.formikField}
                        />
                        <FormikControl
                            control="input"
                            label="FFMI:"
                            disabled
                            type="number"
                            name="result"
                            tooltip="Berechnung"
                            className={classes.formikField}
                        />
                    </>
                )}
            </FormikForm>

            <div className={classes.formelDescription}>
                So wird die Fettfreie Masse ( FFM ) berechnet:
                <h4>FFM = Gewicht x ( 100 - Körper-Fett-Anteil ) / 100</h4>
                mit dem Gewicht in g und dem Körper-Fett-Anteil in %.
            </div>
            <div className={classes.formelDescription}>
                So wird der Fettfreie Masse Index ( FFMI ) berechnet:
                <h4>FFMI = ( FFM / Größe&sup2; ) + 6,3 x ( 1,8 - Größe )</h4>
                mit der FFM in kg und der Größe in m.
            </div>

            <Table className={classes.instructions} responsive>
                <thead>
                    <tr>
                        <th>Interpretation der Muskelentwicklung</th>
                        <th className={classes.male}>FFMI</th>
                        <th className={classes.female}>FFMI</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>sehr schlank, wenig Muskelmasse</td>
                        <td>unter 18</td>
                        <td>unter 14</td>
                    </tr>
                    <tr>
                        <td>durchschnittlich muskulös</td>
                        <td>18-20</td>
                        <td>14-16</td>
                    </tr>
                    <tr>
                        <td>muskulöser Körperbau</td>
                        <td>21-23</td>
                        <td>17-19</td>
                    </tr>
                    <tr>
                        <td>außergewöhnlich muskulöser Körperbau</td>
                        <td>24-25</td>
                        <td>20-21</td>
                    </tr>
                    <tr>
                        <td>ohne Steroide kaum erreichbar</td>
                        <td>über 25</td>
                        <td>über 21</td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}
