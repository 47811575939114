import { HashRouter, Route, Switch } from "react-router-dom"
import { Layout } from "../components/Layout"
import { routes } from "./routes"
import { FourZeroFour } from "./error/FourZeroFour"
import Home from "./Home"
import Impressum from "./Impressum"
import FFMI from "./FFMI"
import NavyKFA from "./NavyKFA"

export default function Pages() {
    /*
    <Route exact path={routes.contact} component={Contact} />
    <Route exact path={routes.login.default} component={Login} />
    <Route path={routes.jakob.default} component={Jakob} />
    <Route exact path={routes.logout} component={Logout} />
    <Route exact path="/skills" component={Skills} />
    <Route exact path="/tagebuch" component={Tagebuch} />
    <Route exact path="/kontenmodel" component={Kontenmodel} />
    <Route exact path="/dezimal-binaer" component={DezimalBinaer} />
    <Route
    exact
    path="/brainTrainSkillGames"
    component={BrainTrainSkillGames}
    />
    <Route exact path="/zitate" component={Zitate} />
    <Route
    exact
    path="/weeklyRandomDiet"
    component={WeeklyRandomDiet}
    />
    <Route path="*" component={FourZeroFour} />
    */

    return (
        <HashRouter>
            <Layout title="Jakobs Toolbox">
                <Switch>
                    <Route exact path={routes.home} component={Home} />
                    <Route exact path={routes.impressum} component={Impressum} />
                    <Route exact path={routes.rechner.ffmi} component={FFMI} />
                    <Route exact path={routes.rechner.navyKFA} component={NavyKFA} />
                    <Route component={FourZeroFour} />
                </Switch>
            </Layout>
        </HashRouter>
    )
}
