import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { OverlayTrigger, OverlayTriggerProps, Tooltip } from "react-bootstrap"

export interface InfoIconWithTooltipProps extends Omit<OverlayTriggerProps, "overlay" | "children"> {
    tooltip?: string
    iconprops?: Partial<FontAwesomeIconProps>
}

export function InfoIconWithTooltip({ tooltip, iconprops, ...rest }: InfoIconWithTooltipProps) {
    return tooltip ? (
        <OverlayTrigger placement="right" overlay={<Tooltip id={`info-tooltip`}>{tooltip}</Tooltip>} {...rest}>
            <FontAwesomeIcon focusable icon={faInfoCircle} tabIndex={0} {...iconprops} className={classNames("ml-2 text-secondary", iconprops?.className)} />
        </OverlayTrigger>
    ) : null
}
