import { ReactNode, useCallback, useState } from "react"
import classes from "./Panel.module.scss"

export interface PanelProps {
    title: string
    children: ReactNode
    open: boolean
}

export function Panel({ title, children, open }: PanelProps) {
    const [isOpen, setIsOpen] = useState(open)

    const handleClick = useCallback(() => {
        if (!isOpen) {
            setIsOpen(true)
        } else {
            setIsOpen(false)
        }
    }, [isOpen, setIsOpen])

    return (
        <div className={classes.panel}>
            <h4 onClick={handleClick} className={isOpen ? classes.active : classes.inactive}>
                {title}
            </h4>
            {isOpen && <div className={classes.panelContent}>{children}</div>}
        </div>
    )
}
