import Logo from "../../Logo"
import Navigation from "../Navigation"
import src from "../../../images/Jakob_1024x1024.jpg"
import classes from "./Header.module.scss"

export function Header() {
    return (
        <div className={classes.header}>
            <div className={classes.logo}>
                <Logo source={src} width="auto" height="100px" />
            </div>
            <div className={classes.title}>
                <h1>Jakob&apos;s Toolbox</h1>
            </div>
            <Navigation />
        </div>
    )
}
