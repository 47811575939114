import { Link as RouterLink } from "react-router-dom"
import classes from "./Link.module.scss"

interface LinkProps {
    name: string
    location: string
    text: string
    onClick?: () => void
}

export default function Link({ name, location, text, onClick }: LinkProps) {
    return (
        <RouterLink id={name} to={location} className={classes.textLink} onClick={onClick}>
            {text}
        </RouterLink>
    )
}
