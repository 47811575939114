import Logo from "../../components/Logo"
import src from "./404_NotFound.png"
import classes from "./FourZeroFour.module.scss"

export const FourZeroFour = () => {
    return (
        <div className={classes.FourZeroFour}>
            <Logo source={src} width="auto" height="auto" />
        </div>
    )
}
