import { faAsterisk } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CSSProperties } from "react"

export function Label(props: any) {
    const style: CSSProperties = { width: "0.875em" }
    const { name, label, required, className } = props
    return (
        <label htmlFor={name} className={className}>
            {label}
            {required && (
                <sup>
                    <FontAwesomeIcon icon={faAsterisk} className="pl-1" style={style} />
                </sup>
            )}
        </label>
    )
}
