import { Field } from "formik"
import { Fragment } from "react"
import { FormikErrorMsg, InfoIconWithTooltip, Label } from ".."

export function RadioButtons(props: any) {
    const { className, name, options, tooltip, ...rest } = props
    return (
        <div className={className}>
            <Label {...rest} />
            {tooltip && <InfoIconWithTooltip tooltip={tooltip} />}
            <Field name={name}>
                {({ field }) => {
                    return options.map((option: any) => {
                        return (
                            <Fragment key={option.key}>
                                <input type="radio" id={option.value} {...field} {...rest} value={option.value} checked={field.value === option.value} />
                                <label htmlFor={option.value}>{option.key}</label>
                            </Fragment>
                        )
                    })
                }}
            </Field>
            <FormikErrorMsg name={name} />
        </div>
    )
}
