import classNames from "classnames"
import { ReactNode } from "react"

export interface ListGroupProps {
    children: ReactNode
    className?: string
}
export function ListGroup({ children, className }: ListGroupProps) {
    return <dl className={classNames("row", className)}>{children}</dl>
}

export interface ListGroupItemProps {
    title: string
    children: ReactNode
    className?: string
}
export function ListGroupItem({ title, children, className }: ListGroupItemProps) {
    return (
        <>
            <dt className={classNames("col-sm-4", className, { "mb-3": !className })}>{title}</dt>
            <dd className="col-sm-8">{children}</dd>
        </>
    )
}
