import DateView from "react-datepicker"
import { Field } from "formik"
import { FormikErrorMsg, InfoIconWithTooltip, Label } from ".."
import "react-datepicker/dist/react-datepicker.css"

export function DatePicker(props: any) {
    const { className, name, tooltip, ...rest } = props
    return (
        <div className={className}>
            <Label {...rest} />
            {tooltip && <InfoIconWithTooltip tooltip={tooltip} />}
            <Field name={name}>
                {({ form, field }) => {
                    const { setFieldValue } = form
                    const { value } = field
                    return <DateView id={name} {...field} {...rest} selected={value} onChange={(val) => setFieldValue(name, val)} />
                }}
            </Field>
            <FormikErrorMsg name={name} />
        </div>
    )
}
